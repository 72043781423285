import { Component, Input, OnInit } from '@angular/core';
import { NavController, Platform, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-popover',
  templateUrl: './update-popover.component.html',
  styleUrls: ['./update-popover.component.scss'],
})
export class UpdatePopoverComponent implements OnInit {
  @Input() title: string;
  @Input() imgSrc: string;
  @Input() text: string;
  @Input() button: any;
  @Input() closeEnable: boolean;
  backBtnSubscription: Subscription;

  constructor(
    public navCtrl: NavController,
    private popoverController: PopoverController,
    private platform: Platform
  ) { }

  ngOnInit() { }

  ionViewWillEnter() {
    this.backBtnSubscription = this.platform.backButton.subscribeWithPriority(9997, () => {
      if (this.closeEnable) {
        this.closePopover();
      }
    });
  }

  async closePopover() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    }
    await this.popoverController.dismiss();
  }

  redirectTo(route) {
    if (route) {
      window.open(route, '_system');
      this.closePopover();
    }
  }

  ionViewDidLeave() {
    if (this.backBtnSubscription) {
      this.backBtnSubscription.unsubscribe();
    }
  }
}
