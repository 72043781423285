import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AutocompleteInputComponent,
      multi: true
    }
  ]
})
export class AutocompleteInputComponent implements ControlValueAccessor {
  @Input('items') set setItems(val: any[]) {
    this.items = val;
    this.list = this.items;
    this.filterList(this.selectValue);
  }
  @Input() disabled = false;
  @Input() placeholder: string;
  @Output() selected = new EventEmitter<any>();

  items: string[];
  itemSize = 44;
  selectValue: string = null;
  open = false;
  list: string[] = [];
  viewportHeight = 300;

  @ViewChild(IonInput, { static: false }) input: IonInput;

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    // console.log('HostListener ', event.target, !this.eRef.nativeElement.contains(event.target));
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  constructor(
    private eRef: ElementRef,
    private ch: ChangeDetectorRef
    ) { }

  onChangeInput(e: CustomEvent) {
    this.selectValue = e.detail.value;
    this.selected.emit(this.selectValue);
    this.filterList(e.detail.value);
    // if (!this.list?.length || this.list[0] === this.selectValue) {
    //   this.open = false;
    // } else {
    //   this.open = true;
    // }
  }

  openList(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.disabled) { this.open = true; }
    this.onTouched();
  }

  onSelect(item: any) {
    this.selectValue = item;
    this.selected.emit(item);
    this.onChange(this.selectValue);
    this.onTouched();
    this.close();
  }

  close() {
    this.open = false;
    this.ch.detectChanges();
    if (this.input?.value) {
      this.input.value = this.selectValue;
    }
  }

  filterList(val) {
    if (Array.isArray(this.items) && val) {
      this.list = this.items.filter(el => el.toLowerCase().includes(val.toLowerCase()));
    } else if (Array.isArray(this.items) && !val && !this.selectValue) {
      this.list = this.items;
    } else {
      this.list = this.items;
      this.onChange(this.selectValue);
    }
    this.calcViewportHeight();
  }

  calcViewportHeight() {
    setTimeout(() => {
      if (this.list?.length) {
        const height = this.list.length * this.itemSize;
        this.viewportHeight = (height > 300) ? 300 : this.list.length ? height : this.itemSize;
      }
    }, 200);
  }

  onChange = (value: any) => { };

  onTouched = () => { };

  writeValue(value: any): void {
    this.selectValue = value;
    if (value) {
      this.filterList(value);
    }
    this.onChange(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate({ value }: FormControl) {
    return !!value ? true : false;
  }
}
