import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {NavigationEnd, Router} from '@angular/router';
import {AlertService} from './core/services/alert.service';

import {environment} from '../environments/environment';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {Network} from '@ionic-native/network/ngx';
import {debounceTime, delay, filter} from 'rxjs/operators';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Storage} from '@ionic/storage';
import { Facebook } from '@ionic-native/facebook/ngx';
import { AnalyticsService } from './core/services/analytics.service';
// import { Smartlook, SmartlookSetupConfig } from '@ionic-native/smartlook/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent {
    public onlineOffline: boolean = navigator.onLine;
    private networkAlert: any;
    constructor(
        private platform: Platform,
        private router: Router,
        private alertService: AlertService,
        private network: Network,
        private oneSignal: OneSignal,
        private screenOrientation: ScreenOrientation,
        private storage: Storage,
        private fb: Facebook,
        private analyticsService: AnalyticsService,
        // private smartlook: Smartlook
    ) {
        this.initializeApp();
        this.networkStatus();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            if (this.platform.is('hybrid')) { // || this.platform.is('android') || this.platform.is('ios')
                // this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
                // console.log('SETTING UP PUSHS');
                this.setupPush();
                this.initializeFacebook();
                // this.smartlook.setupAndStartRecording(new SmartlookSetupConfig('e5180cb2b7185223bd2545adfae39b5f899222ee'));
                this.screenOrientation.lock('portrait');
            }
            this.storage.set('popup', true);
        });
    }

    initializeFacebook() {
        // this.fb.setAutoLogAppEventsEnabled(true);
        // this.fb.setAdvertiserIDCollectionEnabled(true);
        this.fb.setAdvertiserTrackingEnabled(true);

        // logEvent 'content_view'
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (event && this.platform.is('hybrid')) {
                this.analyticsService.fbLogEvent('fb_mobile_content_view', {
                  app_link: this.router.url
                });
            }
        });
    }

    setupPush() {
        // I recommend to put these into your environment.ts
        this.oneSignal.startInit(environment.oneSignalAppId, environment.googleFirebaseSenderId);

        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

        this.oneSignal.getIds().then(ids => {
            this.storage.set('playerID', ids.userId);
        });

        // Notifcation was received in general
        // DONT DELETE!!!!
        // this.oneSignal.handleNotificationReceived().subscribe(data => {
        //   // const msg = data.payload.body;
        //   // const title = data.payload.title;
        //   // const additionalData = data.payload.additionalData;
        //
        //   console.log('handleNotificationReceived: ', data);
        // });

        // Notification was really clicked/opened
        this.oneSignal.handleNotificationOpened().subscribe(data => {
            // const additionalData = data.notification.payload.additionalData;
            console.log('handleNotificationOpened: ', data);
            //
            let url = data.notification.payload.launchURL;
            console.log('url', url);
            if (url && url.substring(0, 3) === 'x:/') {
              url = url.substring(3);
              if (data.notification.isAppInFocus) {
                  console.log('isAppInFocus true');
                  this.router.navigateByUrl(url);
              } else {
                  console.log('isAppInFocus false');

                  function delay(ms) {
                      return new Promise(resolve => setTimeout(resolve, ms));
                  }

                  this.router.navigateByUrl('/home/catalogue').then(() => {
                      delay(1000).then(() => this.router.navigateByUrl(url));
                  });
              }
            }
        });

        // DONT DELETE!!!!
        // this.oneSignal.handleInAppMessageClicked().subscribe(action => {
        //     console.log('handleInAppMessageClicked: ', action);
        //     let innerUrl = action.click_name;
        //     if (innerUrl && innerUrl.substring(0, 7) === 'route:/') {
        //         innerUrl = innerUrl.substring(7);
        //         action.closes_message = true;
        //         this.router.navigateByUrl(innerUrl);
        //     }
        // });

        this.oneSignal.endInit();
    }

    networkStatus() {
       /*  window.addEventListener('offline', () => {
            this.alertService.presentErr();
        });
        window.addEventListener('online', () => {
            location.reload();
        }); */
        this.network.onDisconnect()
        .pipe(
            debounceTime(1000),
            delay(1000)
        )
        .subscribe(async () => {
            console.log('network disconnect');
            this.alertService.presentErr((modal: any) => this.networkAlert = modal);
        });

        this.network.onConnect()
        .pipe(
            debounceTime(1000),
            delay(1000)
        )
        .subscribe(() => {
            console.log('network connected!');
            if (this.networkAlert) {
                this.networkAlert.dismiss();
            }
        });
    }

    includes(data) {
        return this.router.url.includes(data);
    }
}
