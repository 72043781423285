// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
    production: false,
    // dev api
    // origin: 'https://demo.sushi-go.com.ua',
    // prod api
    origin: 'https://sushi-go.com.ua',
    wcEndpoint: 'wp-json/wc/v3',
    wpv2Endpoint: 'wp-json/wp/v2',
    jwtEndpoint: 'wp-json/jwt-auth/v1',
    acfEndpoint: 'wp-json/acf/v3',
    authEndpoint: 'wp-json/auth',
    woocommerce: {
        // Sushi•Go
        // dev api
        // consumer_key: 'ck_e12a5fedb0fe1fed42e1ee3494ee00eccbe06dfb',
        // consumer_secret: 'cs_5cd182b223158dfe51c609b9ed8ad4214db46c91'
        // prod api
        consumer_key: 'ck_bd047f6414bca0d61c4c789133fa6a4a8cfb582f',
        consumer_secret: 'cs_0fd2d210bb42e23feca632c777576389b7adb34c'
    },
    oneSignalAppId: '781d7618-9db6-4440-a82c-7808738cc1c6',
    googleFirebaseSenderId: '487974968198'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
