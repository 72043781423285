import { Injectable } from '@angular/core';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private firebaseAnalytics: FirebaseAnalytics,
    private fb: Facebook,
    private platform: Platform
    ) { }

  logEvent(name?, params?) {
    // console.log('NAME-> ', name, 'PARAMS->' , params);
    if (this.platform.is('hybrid')) {
      this.firebaseAnalytics.logEvent(
        name, params
      ).then((res: any) => console.log(`RESSSSSSSSSSSSSSSSSSSSSSS NAME->${name} PARAMS->${params}`, res))
      .catch((error: any) => console.error(`ERRORRRRRRRRRRRRRRRRRR NAME->${name} PARAMS->${params}`, error));
    }
  }

  fbLogEvent(name?, params?, valueToSum?) {
    // console.log('NAME-> ', name, 'PARAMS-> ' , params, 'VALUETOSUM-> ', valueToSum);
    if (this.platform.is('hybrid')) {
      this.fb.logEvent(
        name, params, valueToSum
      ).then((res: any) => console.log(`RESSSSSSSSSSSSSSSSSSSSSSS NAME->${name} PARAMS->${params} VALUETOSUM->${valueToSum}`, res))
      .catch((error: any) => console.error(`ERRORRRRRRRRRRRRRRRRRR NAME->${name} PARAMS->${params} VALUETOSUM->${valueToSum}`, error));
    }
  }
}
