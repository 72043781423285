import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-respond-thanks-popup',
    templateUrl: './respond-thanks-popup.component.html',
    styleUrls: ['./respond-thanks-popup.component.scss'],
})

export class RespondThanksPopupComponent {

    constructor(
        private router: Router,
        private modalCtrl: ModalController) {
    }

    dismiss() {
        this.modalCtrl.dismiss({
            dismissed: true
        });
    }

    navigateToHome() {
        this.router.navigate(['/home']);
        this.dismiss();
    }
}
