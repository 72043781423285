import { Directive, HostListener, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appPhone]'
})
export class PhoneDirective implements AfterViewInit {
  removeUnnecessary = true;

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.onkeydown =  function(event){
      if (event.keyCode < 48 || event.keyCode > 57) {
        this.removeUnnecessary = false;
      } else {
        this.removeUnnecessary = true;
      }
    };
  }

  ngAfterViewInit() {
    let phone;
    this.elementRef.nativeElement.value ? phone = this.elementRef.nativeElement.value : phone = this.elementRef.nativeElement.innerHTML;
    let res = [];

    if (phone.length === 13) {
      res = phone.split('');
      res.splice(3, 0, ' (');
      res.splice(7, 0, ') ');
      res.splice(11, 0, ' ');
      res.splice(14, 0, ' ');
      const finely = res.join('');
      return this.elementRef.nativeElement.value ? this.elementRef.nativeElement.value = finely
        : this.elementRef.nativeElement.innerHTML = finely;
    } else if (phone.length === 12) {
      res = phone.split('');
      res.splice(0, 0, '+');
      res.splice(3, 0, ' (');
      res.splice(7, 0, ') ');
      res.splice(11, 0, ' ');
      res.splice(14, 0, ' ');
      const finely = res.join('');
      return this.elementRef.nativeElement.value ? this.elementRef.nativeElement.value = finely
        : this.elementRef.nativeElement.innerHTML = finely;
    } else if (phone.length === 10) {
      res = phone.split('');
      res.splice(0, 0, '+38 (');
      res.splice(4, 0, ') ');
      res.splice(8, 0, ' ');
      res.splice(11, 0, ' ');
      const finely = res.join('');
      return this.elementRef.nativeElement.value ? this.elementRef.nativeElement.value = finely
        : this.elementRef.nativeElement.innerHTML = finely;
    }
  }

  @HostListener('keyup') inputKeyup() {
    let phone = this.elementRef.nativeElement.value.replace(/(?=[^0-9\s\(\)\+])./g, '');
    if (phone.length < 6 && this.removeUnnecessary) {
      phone = phone.replace(/^[0-9]{1}$/g, `+38 (${phone}`);
      phone = phone.replace(/^\+$/g, `${phone}38 (`);
      phone = phone.replace(/^\+3$/g, `${phone}8 (`);
      phone = phone.replace(/^\+38$/g, `${phone} (`);
      phone = phone.replace(/^\+38\s$/g, `${phone}(`);
      return this.elementRef.nativeElement.value = phone.replace(/(?=[^0-9\s\(\)\+])./g, '');
    } else if (phone.length > 15 && this.removeUnnecessary) {
      phone = phone.replace(/^(\+38\s\([0-9]{3}\)\s[0-9]{3}\s[0-9]{3})$/g, `${phone.slice(0, -1)} ${phone.substr(-1, 1)}`);
      return this.elementRef.nativeElement.value = phone.replace(/(?=[^0-9\s\(\)\+])./g, '');
    } else if (phone.length > 12 && this.removeUnnecessary) {
      phone = phone.replace(/^(\+38\s\([0-9]{3}\)\s[0-9]{4})$/g, `${phone.slice(0, -1)} ${phone.substr(-1, 1)}`);
      return this.elementRef.nativeElement.value = phone.replace(/(?=[^0-9\s\(\)\+])./g, '');
    } else if (phone.length > 7 && this.removeUnnecessary) {
      phone = phone.replace(/^(\+38\s\([0-9]{4})$/g, `${phone.slice(0, -1)}) ${phone.substr(-1, 1)}`);
      phone = phone.replace(/^(\+38\s\([0-9]{3}\)[0-9]{1})$/g, `${phone.slice(0, -1)} ${phone.substr(-1, 1)}`);
      return this.elementRef.nativeElement.value = phone.replace(/(?=[^0-9\s\(\)\+])./g, '');
    }
    return this.elementRef.nativeElement.value = phone.replace(/(?=[^0-9\s\(\)\+])./g, '');
  }
}
