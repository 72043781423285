import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteInputComponent } from './autocomplete-input.component';
import { IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AutocompleteInputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ScrollingModule,
    TranslateModule
  ],
  exports: [
    AutocompleteInputComponent
  ]
})
export class AutocompleteInputModule { }
