import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { WoocommerceCustomerService } from 'src/app/core/services/wooapi/wooApi';
import { PersonalService } from '../../../home/personal/personal.service';
import { AlertService } from '../../../core/services/alert.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.page.html',
  styleUrls: ['./popup.page.scss'],
})
export class PopupPage implements OnInit {

  @Input() paramTitle: string;
  @Input() valueAdress: any;
  @Input() address: any;
  @Input() editable: any;
  @Input() removeItem: any;
  @Input() outCities: any;
  @Input() iikoCity: string = null;

  adressForm: FormGroup;
  spiner = true;
  isShowPhone = false;
  states: any;
  streets: Array<any>;
  index: number;
  valueChangesCity: Subscription;

  constructor(
    private modalController: ModalController,
    private woocommerceCustomerService: WoocommerceCustomerService,
    private alertController: AlertController,
    private personalService: PersonalService,
    private storage: Storage,
    private alertService: AlertService,
    private translate: TranslateService
  ) {
    this.adressForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      street: new FormControl({ value: null, disabled: true }, Validators.required),
      house: new FormControl(null, Validators.required),
      entrance: new FormControl(null),
      floor: new FormControl(null),
      flat: new FormControl(null),
      note: new FormControl(null),
      is_main: new FormControl({ value: null, disabled: this.address ? this.address?.is_main : false })
    });
  }

  ngOnInit() {
    this.getCity();

    this.index = this.valueAdress.addresses ? this.valueAdress.addresses.findIndex(x => x === this.address) : null;

    if (this.valueAdress.addresses === false) {
      this.adressForm.get('is_main').setValue(true);
      this.adressForm.get('is_main').disable();
    }
    if (this.valueAdress && this.address && this.editable) {
      this.adressForm.patchValue(this.address);
      if (this.adressForm.get('is_main').value === true) {
        this.adressForm.get('is_main').disable();
      }
      this.adressForm.get('street').enable();
    }

    this.valueChangesCity = this.adressForm.get('city').valueChanges.subscribe((val) => {
      this.adressForm.get('street').reset();
      if (val) {
        this.streets = this.states[val];
      }
      this.adressForm.get('street').enable();
    });
  }

  getCity() {
    this.personalService.getCity(this.iikoCity)
      .pipe(finalize(() => {
        this.spiner = false;
      }))
      .subscribe(
        data => {
          this.states = data;
          if (this.adressForm.get('city').value) {
            this.streets = this.states[this.adressForm.get('city').value];
            this.adressForm.get('street').enable();
            this.adressForm.get('street').setValue(this.address.street);
          }
        },
        err => {
          this.alertService.presentAlert(err);
        }
      );
  }

  async close(value = null) {
    await this.modalController.dismiss(value);
  }


  // remove user address
  async remove() {
    this.spiner = true;
    const alert = await this.alertController.create({
      header: this.translate.instant('POPUP.confirm'),
      buttons: [
        {
          text: this.translate.instant('POPUP.cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            return;
          }
        }, {
          text: this.translate.instant('POPUP.del'),
          handler: () => {
            this.valueAdress.addresses.splice(this.index, 1);
            delete this.valueAdress.photo;
            delete this.valueAdress.meta_data;

            this.woocommerceCustomerService.updateCustomers(this.valueAdress.id, this.valueAdress)
              .pipe(finalize(() =>
                this.spiner = false
              )).subscribe(data => {
                const onClosedData = {
                  responce: data
                };
                this.storage.set('user', data);
                this.modalController.dismiss(onClosedData);
              }, error => {
                this.alertService.presentAlert(error);
              });
          }
        }
      ]
    });

    await alert.present();
  }

  // add and edit user adress
  onSubmitForm() {
    this.spiner = true;

    if (this.valueAdress.addresses === false) {
      this.adressForm.get('is_main').enable();
      this.adressForm.get('is_main').setValue(true);
    }
    if (this.valueAdress.addresses !== false && this.adressForm.get('is_main').value === true) {
      this.valueAdress.addresses.forEach(element => {
        element.is_main = false;
      });
    }
    if (this.editable) {
      this.adressForm.get('is_main').enable();
      this.valueAdress.addresses.splice(this.index, 1, this.adressForm.value);
    } else {
      if (this.valueAdress.addresses === false) {
        this.valueAdress.addresses = [];
      }
      this.valueAdress.addresses.push(this.adressForm.value);
    }

    delete this.valueAdress.photo;
    delete this.valueAdress.meta_data;
    this.valueAdress.billing.phone = '38' + this.valueAdress.billing.phone.replace(/\+|\s|\(|\)/g, '').slice(-10);

    this.woocommerceCustomerService.updateCustomers(this.valueAdress.id, this.valueAdress)
      .pipe(finalize(() =>
        this.spiner = false
      ))
      .subscribe(
        data => {
          const onClosedData = {
            responce: data
          };
          this.storage.set('user', data);
          this.modalController.dismiss(onClosedData);
        },
        error => {
          this.alertService.presentAlert(error);
        }
      );
  }

  // cancel modal when did miss
  async cancelModal() {
    const onClosedData: object = null;
    if (this.valueChangesCity) {
      this.valueChangesCity.unsubscribe();
    }
    await this.modalController.dismiss(onClosedData);
  }
}




