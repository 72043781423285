import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError, Subject, ReplaySubject } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    // logout$: Subject<any> = new Subject<any>();
    private user$: ReplaySubject<any> = new ReplaySubject<any>(1);

    constructor(
        private http: HttpClient,
        private storage: Storage
    ) {
        this.storage.ready().then(() => {
            this.storage.get('user').then(user => {
                this.user$.next(user);
            });
        });
    }

    getData() {
        return this.http.get(`${environment.acfEndpoint}/options/options`).pipe(data => data);
    }

    // getAvatar (token) {
    //     const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    //     return this.http.get(`${environment.wpv2Endpoint}/users/me`, {headers}).pipe(
    //         catchError(err => {
    //             this.logout$.next();
    //             return throwError(err);
    //         }));
    // }

    getHomeCategories() {
        return this.http.get(`sub_cat`).pipe(data => data);
    }

    setUserInStorage(body) {
        this.storage.ready().then(() => {
            body ? this.storage.set('user', body) : this.storage.remove('user');
            this.user$.next(body);
        });
    }

    get getUserIntoStorage() {
        return this.user$;
    }
}
