import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {LanguageCityService} from './language-city.service';

@Injectable()
export class AppStartResolver implements Resolve<any> {
    constructor(private languageService: LanguageCityService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.languageService.setInitialCityAndLanguage ().then((appStartData) => {
            return appStartData;
        });
    }
}
